import React from 'react'
import { Testimonials } from '../Components/About/Testimonials'
import { Teams } from '../Components/About/Teams'
import HeadImage from '../assets/Aanya-homes-head.png'
import HeadImage2 from '../assets/Aanya-homes-head2.png'

export const About = () => {
    window.scrollTo(0, 0);
    return (
        <div className='w-[82vw] my-[4vw] mx-[auto]'>
            <div className="text-white">
                <div className="flex flex-col mt-6 lg:mt-0 md:mt-0 sm:mt-0 xs:mt-0">
                    <h1 className="text-3xl lg:text-4xl md:text-3xl sm:text-3xl xs:text-3xl text-white">About Us</h1>
                    <p className="mt-4 lg:text-lg md:text-base sm:text-base xs:text-base">Welcome to G.A.H Aanya Homes Pvt Ltd, where your dreams find a home since 2002. Led by the visionary <span className='text-white'>Mr. Shiva Goswami</span>, with over two decades of experience in the real estate industry, Mr. Goswami has spearheaded numerous successful projects that have transformed landscapes and enriched lives across multiple states and cities including Delhi, Gurugram, Noida, Punjab, Chandigarh, Mumbai, and Goa.</p>
                    <p className="mt-4 lg:text-lg md:text-base sm:text-base xs:text-base">Moreover, we are proud to be RERA Approved - registration no. DLRERA2023A0152, ensuring transparency, accountability, and peace of mind for our valued clients.</p>
                    <p className="mt-4 lg:text-lg md:text-base sm:text-base xs:text-base">Formerly known as Sai Construction, our journey has been one of evolution and excellence. With a commitment to quality and integrity, we specialize in the sale of diverse properties, ranging from residential havens to bustling commercial hubs and captivating malls. Our expertise extends beyond sales, as we also take pride in crafting bespoke spaces through our construction services. Whether it's residential flats, commercial shops, or luxurious farmhouses, we bring your vision to life with meticulous attention to detail and craftsmanship.</p>
                </div>
                <div className="flex flex-col lg:flex-row md:flex-col sm:flex-col xs:flex-col items-center lg:gap-16 gap-5 mt-8 lg:mt-12 md:mt-12 sm:mt-12 xs:mt-12">
                    <div className="lg:w-1/4 md:w-full sm:w-full xs:w-full order-2 lg:order-1 md:order-1 sm:order-1 xs:order-1">
                        <img src={HeadImage} className="w-35 rounded-2xl" alt="about director" />
                    </div>
                    <div className="flex flex-col lg:w-3/4 md:w-full sm:w-full xs:w-full mt-6 lg:mt-0 md:mt-0 sm:mt-0 xs:mt-0 order-1 lg:order-2 md:order-2 sm:order-2 xs:order-2">
                        <h1 className="text-3xl lg:text-4xl md:text-3xl sm:text-3xl xs:text-3xl text-white">Mr. Shiva Goswami, Director</h1>
                        <p className="mt-4 lg:text-lg md:text-base sm:text-base xs:text-base">Mr. Shiva Goswami is a visionary leader with over two decades of experience in the real estate industry. He has spearheaded numerous successful projects that have transformed landscapes and enriched lives across multiple states and cities including Delhi, Gurugram, Noida, Punjab, Chandigarh, Mumbai, and Goa.</p>
                        <p className="mt-4 lg:text-lg md:text-base sm:text-base xs:text-base">Under his leadership, G.A.H Aanya Homes Pvt Ltd has evolved into a pioneering force in the real estate sector, committed to quality, integrity, and customer satisfaction.</p>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row md:flex-col sm:flex-col xs:flex-col items-center lg:gap-16 gap-5 mt-8 lg:mt-12 md:mt-12 sm:mt-12 xs:mt-12">
                    <div className="flex flex-col lg:w-3/4 md:w-full sm:w-full xs:w-full mt-6 lg:mt-0 md:mt-0 sm:mt-0 xs:mt-0">
                        <h1 className="text-3xl lg:text-4xl md:text-3xl sm:text-3xl xs:text-3xl text-white">Sapna Goswami, Director</h1>
                        <p className="mt-4 lg:text-lg md:text-base sm:text-base xs:text-base">Sapna Goswami is a respected figure in the real estate industry, known for her strategic vision and leadership skills. As a director of G.A.H Aanya Homes Pvt Ltd, she plays a pivotal role in driving the company's growth and expansion.</p>
                    </div>
                    <div className="lg:w-1/4 md:w-full sm:w-full xs:w-full">
                        <img src={HeadImage2} className="w-35 rounded-2xl" alt="about director" />
                    </div>
                </div>
                <div className="flex flex-col mt-8 lg:mt-12 md:mt-12 sm:mt-12 xs:mt-12">
                    <h1 className="text-3xl lg:text-4xl md:text-3xl sm:text-3xl xs:text-3xl text-white">Journey to Success</h1>
                    <p className="mt-4 lg:text-lg md:text-base sm:text-base xs:text-base">At G.A.H Aanya Homes, we understand that a home is more than just a structure; it's a sanctuary where memories are made and dreams unfold. That's why we go above and beyond to ensure that each property we offer is not just a place to live or work but a reflection of your aspirations and lifestyle.</p>
                    <p className="mt-4 lg:text-lg md:text-base sm:text-base xs:text-base">Driven by a passion for excellence and a relentless pursuit of customer satisfaction, we strive to exceed expectations at every turn. Our success is measured not only by the properties we build and sell but also by the relationships we nurture and the communities we enrich along the way.</p>
                    <p className="mt-4 lg:text-lg md:text-base sm:text-base xs:text-base">Join us on this journey of growth and prosperity as we continue to shape the future of real estate, one dream at a time. Welcome to G.A.H Aanya Homes Pvt Ltd, where every property tells a story, and every client is family.</p>
                </div>
            </div>
            <Testimonials />
            <Teams />
        </div>
    )
}