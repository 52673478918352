import { Route, Routes } from "react-router-dom";
import Pages from "../Wrappers/UserWrapper";

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.HomepageLayout />} />
      <Route path="/lander" element={<Pages.HomepageLayout />} />
      <Route path="/about" element={<Pages.AboutLayout />} />
      <Route path="/companies/:name" element={<Pages.CompanyLayout />} />
      <Route path="/companies/:name/projects/:id" element={<Pages.ProjectLayout />} />
      <Route path="/our-projects/:name" element={<Pages.ProjectLayout />} />
      <Route path="/projects/:name" element={<Pages.ProjectLayout />} />
      <Route path="/blogs" element={<Pages.BlogsLayout />} />
      <Route path="/blogs/:id" element={<Pages.BlogDetailLayout />} />
      <Route path="/our-projects" element={<Pages.OurProjectsLayout />} />
      <Route path="/career" element={<Pages.CarrerLayout />} />
      <Route path="/contact" element={<Pages.ContactLayout/>} />
      <Route path="/privacy-notice" element={<Pages.PrivacyNoticeLayout/>} />
      <Route path="*" element={<Pages.NotFoundLayout />} />
    </Routes>
  );
}