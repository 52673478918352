import { Link } from 'react-router-dom';
import logo from '../assets/Aanya-homes-logo.png'

export default function Footer() {
    return (
        <div className="text-white">
            <hr />
            <br />
            <div className='border-red m-auto'>
                <div>
                    <div className="container w-[82vw] m-auto py-10">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-15">
                            <div className="flex flex-col items-center md:items-start gap-5 mb-5">
                                <h2 className="text-xl md:text-2xl text-white font-bold mb-4">About</h2>
                                <Link to="/about" className="hover:text-gray-700">Our story</Link>
                                <Link to="/contact" className="hover:text-gray-700">Contact us</Link>
                                <Link to="/privacy-notice" className="hover:text-gray-700">Privacy notice</Link>
                            </div>
                            <div className="flex flex-col items-center md:items-start gap-5 mb-5">
                                <h2 className="text-xl md:text-2xl text-white font-bold mb-4">Companies With Us</h2>
                                <Link to="/companies/BhutaniInfraNoida" className="hover:text-gray-700">Bhutani Infra Noida</Link>
                                <Link to="/companies/YugenInfra" className="hover:text-gray-700">Yugen Infra</Link>
                                <Link to="/companies/SignatureGlobal" className="hover:text-gray-700">Signature Global</Link>
                            </div>
                            <div className="flex flex-col items-center md:items-start gap-5 mb-5">
                                <h2 className="text-xl md:text-2xl text-white font-bold mb-4">Customer Care</h2>
                                <p>Timings: 09 AM - 07pm PM (Mon - Sun)</p>
                                <a href="tel:+917027070207" className="hover:text-gray-700">Call: +91 7027070207</a>
                                <a href="mailto:aanyahomes@gmail.com" className="hover:text-gray-700">E-Mail: aanyahomes@gmail.com</a>
                            </div>
                            <div className="flex justify-center md:justify-start items-center border-0px-solid-red">
                                <Link to="/">
                                    <img src={logo} alt="logo" className="h-32 md:h-40 ml-0 sm:ml-5" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <p className="text-sm text-center mt-4">&copy; 2024 G.A.H Aanya Homes PVT.LTD All rights reserved</p>
            <br />
        </div>
    );
}