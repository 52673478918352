import React from 'react';
import { BlogMain } from '../Components/Blogs/BlogMain';
import GurugramAndNoida from '../assets/blogs/GurugramAndNoida.png';
import MaximizeReturns from '../assets/blogs/Maximize-return.png';
import CommRestProperty from '../assets/blogs/Commercial-Residencial-Property.png';
import RealEstate from '../assets/blogs/Real-Estate.png';
import InvestmentRisk from '../assets/blogs/Investment-risk.png';

export const Blogs = () => {
  window.scrollTo(0, 0);

  const data = [
    {
      id: 1,
      date: '2024-03-28T19:01:27Z',
      title: 'Where should one invest gurugram or Noida / Which location presents the better investment opportunity: Gurugram or Noida?',
      details: 'Are you on the lookout for promising real estate investment prospects in Gurugram and Noida? Your search ends here! These regions, characterized by....',
      photo: GurugramAndNoida
    },
    {
      id: 2,
      date: '2024-03-28T19:01:27Z',
      title: 'How to get good returns appreciation profit out of less investment in real estate? / How can one maximize returns, appreciation, and profits from a modest investment in real estate?',
      details: 'Are you intrigued by real estate investment but deterred by budget constraints? Fear not! This guide reveals effective strategies to attain significant returns....',
      photo: MaximizeReturns
    },
    {
      id: 3,
      date: '2024-03-28T19:01:27Z',
      title: 'What is the difference between commercial and residential property?',
      details: 'In the realm of real estate investment, discerning the disparities between commercial and residential properties is vital for making informed decisions that yield favourable profits....',
      photo: CommRestProperty
    },
    {
      id: 4,
      date: '2024-03-28T19:01:27Z',
      title: 'Exploring the Safest Investment in the World: Real Estate?',
      details: 'In a world marked by economic fluctuations, identifying the most secure investment option becomes imperative. Among the plethora of choices, real estate emerges as an unwavering and....',
      photo: RealEstate
    },
    {
      id: 5,
      date: '2024-03-28T19:01:27Z',
      title: 'What strategies can investors employ to maximize returns and minimize risks when investing in real estate?',
      details: 'Real estate investment presents promising opportunities for wealth accumulation, yet it also entails inherent risks. To navigate this dynamic market successfully, investors must adopt....',
      photo: InvestmentRisk
    }
  ]

  return (
    <div className='w-[82vw] my-[4vw] mx-[auto] text-[#94A6B8]]'>
      {data.map(blog => <BlogMain key={blog.id} blog={blog} />)}
    </div>
  )
}