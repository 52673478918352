import React from 'react'
import { Link } from "react-router-dom"

export const NotFound = () => {
  window.scrollTo(0, 0);
  return (
    // <Heading display="inline-block" as="h2" size="2xl" bgGradient="linear(to-r, teal.400, teal.600)" backgroundClip="text"> 404</Heading>
    <div className="text-center py-10 px-6">
      <h2 className="inline-block text-4xl text-teal-400 bg-gradient-to-r from-teal-400 to-teal-500 bg-clip-text">404</h2>
      <p className="text-xl mt-3 mb-2">Page Not Found</p>
      <p className="text-gray-500 mb-6">The page you're looking for does not seem to exist</p>

      <button className="bg-teal-500 hover:bg-teal-600 text-white font-semibold py-2 px-4 rounded">
        <Link to="/">Go to Home</Link>
      </button>
    </div>
  )
}