import React from 'react'
import { MdPhone, MdEmail, MdLocationOn, MdFacebook, MdOutlineEmail } from 'react-icons/md'
import { BsPerson, BsInstagram, BsLinkedin } from 'react-icons/bs'

export const Contact = () => {
  const initForm = { name: '', email: '', message: '' };
  const [form, setForm] = React.useState(initForm);
  window.scrollTo(0, 0);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value })
  }

  return (
    <div className='bg-white w-[82vw] my-[4vw] mx-[auto] overflow-hidden'>
        <div className='text-white rounded-md sm:m-4 md:m-16 lg:m-10 sm:p-5 md:p-5 lg:p-16'>
          <div className='flex flex-col-reverse md:flex-row justify-evenly items-center gap-10'>
            <div>
              <h1 className='font-bold text-3xl'>Contact</h1>
              <p className='sm:mt-3 md:mt-3 lg:mt-5'>Fill up the form below to contact</p>
              <div className='base:py-5 sm:py-5 md:py-8 lg:py-10'>
                <div className='flex flex-col gap-3'>
                  <a href='tel:7027070207' className='flex items-center gap-2 justify-center h-[48px] w-[220px] text-[#DCE2FF] hover:border border-blue-500 font-bold rounded-md'><MdPhone color="#1970F1" size="20px" />+91-7027070207</a>
                  <a href='mailto:aanyahomes@gmail.com' className='flex items-center gap-2 justify-center h-[48px] w-[220px] text-[#DCE2FF] hover:border border-blue-500 font-bold rounded-md'><MdEmail color="#1970F1" size="20px" />aanyahomes@gmail.com</a>
                  <button className='flex items-center gap-2 justify-center h-[48px] w-[220px] text-[#DCE2FF] hover:border border-blue-500 font-bold rounded-md'><MdLocationOn color="#1970F1" size="20px" />Delhi, India</button>
                </div>
              </div>
              <div className='flex justify-center gap-5 px-5 md:mt-10'>
                <button as={'a'} href='https://www.facebook.com/people/Shiva-Geetanjali/pfbid0K5qYcrcktoNN14xZ2aQGedCDGm64hVfNC4QsKyRmXyJGotrtVr1CwWVwJwLc3Qkml' target='_blank' className='rounded-full hover:bg-white text-[#3b5999]'><MdFacebook size="28px" /></button>
                <button as={'a'} href='https://www.instagram.com/aanyahomes.pvt.ltd' target='_blank' className='rounded-full hover:bg-white text-[#e4405f]'><BsInstagram size="28px" /></button>
                <button as={'a'} href='https://www.instagram.com/aanyahomes.pvt.ltd' target='_blank' className='rounded-full hover:bg-white text-[#0077b5]'><BsLinkedin size="28px" /></button>
              </div>
            </div>
            <div className='rounded p-8 text-white' style={{ border: '5px solid grey' }}>
                <form action="https://formsubmit.co/aanyahomes@gmail.com" method="POST">
                  <div className="grid grid-cols-1 gap-5">
                    <div className="flex flex-col gap-3">
                      <label className="text-white">Your Name</label>
                      <div className="relative border border-white">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"><BsPerson className="text-white" /></span>
                        <input type="text" required name="name" value={form.name} onChange={handleFormChange} className="rounded-md pl-10 py-2 focus:outline-none focus:border-transparent" />
                      </div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <label className="text-white">Mail</label>
                      <div className="relative border border-white">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"><MdOutlineEmail className="text-white" /></span>
                        <input type="email" required name="email" value={form.email} onChange={handleFormChange} className="rounded-md pl-10 py-2 focus:outline-none focus:border-transparent" />
                      </div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <label className="text-white">Message</label>
                      <textarea required name="message" value={form.message} onChange={handleFormChange} className="border border-white rounded-md pl-3 py-2 focus:outline-none" placeholder="message" />
                    </div>
                    <div><button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Send Message</button></div>
                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
  )
}