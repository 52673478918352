import React from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom';

export const Company = () => {
    const navigate = useNavigate();
    const path = useLocation().pathname.split('companies/')[1];
    const [dataState, setDataState] = React.useState([]);

    const data = {
        BhutaniInfraNoida: [
            {
                id: 1,
                name: "Cyberthum",
                href: 'projects/cyberthum',
                src: "https://www.bhutanigroup.com/assets/images/projects/cyber.webp",
                about: "Standing at the cutting edge of technology, Cyberthum is a 26.8 acre mixed-use complex and offers commercial property in Noida..."
            },
            {
                id: 2,
                name: "Avenue 62",
                href: 'projects/avenue62',
                src: 'https://www.bhutanigroup.com/assets/images/projects/a1b9e59be5f7a5ba4ee5d5966befb17e.webp',
                about: 'The future-ready retail space will offer a wider experience than just physical shops but rather places in which to meet and enage wit...'
            },
            {
                id: 3,
                name: "City Center 150",
                href: "projects/citycenter150",
                src: "https://www.bhutanigroup.com/assets/images/projects/6321e64ad1b84a07df4a20a80ac54a7c.webp",
                about: "City Center 150 is the new address for experimential retail. It offers a glimpse into the future of listyle and retail areas..."
            }
        ],
        Galaxy: [
            {
                id: 4,
                name: "Sawasdee Heights",
                href: "projects/sawasdeeHeights",
                src: "https://sawasdeeindia.com/wp-content/uploads/2023/12/sawasdee-heights.png",
                about: "Sawasdee Heights is a residential project strategically located in the adjoining prestigious areas of Punjabi Bagh and..."
            },
            {
                id: 5,
                name: "Sawasdee NSP",
                href: "projects/sawasdeeNsp",
                src: "https://sawasdeeindia.com/wp-content/uploads/2023/03/NSP.jpg",
                about: "Netaji Subash Palace (NSP), has overtime become the hub for commercial and office spaces in Delhi..."
            },
            {
                id: 6,
                name: "Sawasdee Gems",
                href: "projects/sawasdeeGems",
                src: "https://sawasdeegems.com/wp-content/uploads/2023/06/asdf52.png",
                about: "Sawasdee Gems is a retail & office development that will consist of the best of facilities. Sawasdee Gems Delhi is..."
            },
        ],
        EON: [
            {
                id: 7,
                name: "Fair Fox",
                href: "projects/fairfox",
                src: "https://thefairfox.co.in/images/img14.webp",
                about: "Fairfox IT infra, Simple philosophy is based on assuring Quality Construction, Timely Delivery, Corporates..."
            }
        ],
        YugenInfra: [
            {
                id: 8,
                name: "Yugen",
                href: "projects/yugen",
                src: "https://yugeninfra.com/wp-content/uploads/2023/10/Gurgaon-apartment-DLF-4-866x577-1.webp",
                about: "We at Yugen Infra plan to delight Indians with luxurious yet affordable properties..."
            }
        ],
        SignatureGlobal: [
            {
                id: 9,
                name: "Signature Global City 79B",
                href: "projects/signatureGlobalCity79B",
                src: "https://signatureglobalcity79b.in/html/images/slide-banner/2.jpg",
                about: "Signature Global City 79B creates an exclusive ambience that echoes with ageless folklore of the Aravallis,..."
            },
        ],
        OCUSMedley: [
            {
                id: 10,
                name: "Galleria 99",
                href: "projects/galleria99",
                src: "https://ocusmedley.com/wp-content/uploads/2023/07/specs-medley-galleria.jpg",
                about: "Part of a mega high-street improvement bang on Dwarka Expressway – Gurugram. The Retail Shops in Ocus Medley 99..."
            }
        ],
        Tarc: [
            {
                id: 11,
                name: "Kailasa",
                href: "projects/kailasa",
                src: "https://kailasa-tarc.com/images/g1.webp",
                about: "TARC Kailāsa is located iconically on Patel Road. Patel Road serves as the main artery to Central Delhi,..."
            },
        ],
        Migsun: [
            {
                id: 12,
                name: "Migsun Rohini Central",
                href: "projects/migsunRohiniCentral",
                src: "https://migsunrohinicentral.com/wp-content/uploads/2024/01/arialview-1536x959.jpg",
                about: "Nestled in the vibrant city of Delhi, Migsun Rohini Central stands as a prominent high-street commercial project developed by the prestigious Migsun Group..."
            },
        ],
        M3M: [
            {
                id: 13,
                name: "The Cullinan",
                href: "projects/theCullinan",
                src: "https://img.staticmb.com/mbimages/project/2023/07/24/Project-Photo-3-M3M-The-Cullinan-Noida-5410763_1200_1600.jpg",
                about: "Legends live forever, so will the Cullinan remain a legend for eternity and just as the uncut diamond was later cut and polished into 9 different gems..."
            },
            {
                id: 14,
                name: "The Line",
                href: "projects/theLine",
                src: "http://theline-m3m.com/img/3.jpg",
                about: "M3M The LINE stands tall as a testament to opulence, opportunity and growth. This premium commercial real estate project in Noida Sector 72..."
            }
        ],
        TheHouseOfAbhinandanLodha: [
            {
                id: 15,
                name: "Gulf of Goa",
                href: "projects/gulfOfGoa",
                src: "https://lodhagulfofgoa.com/images/gallery/gulf-of-goa-abhinandan-lodha-1s.webp",
                about: "The prestigious shores have hosted a symphony of blockbusters, with the likes of Goa Marriot Hotel setting the stage for countless silver screen memories...."
            }
        ]
    }

    React.useEffect(() => {
        setDataState(data[path]);
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="flex flex-col p-8 gap-4 w-[82vw] mx-auto">
            <h2 className="text-2xl text-center text-white font-bold">Projects</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-x-7 gap-y-20">
                {dataState?.map((el, index) => (
                    <div key={index} className="rounded-lg shadow-[0_10px_25px_grey] hover:shadow-[0_10px_25px_'#94A6B8] transition duration-500 ease-in-out transform hover:scale-105">
                        <div className="w-full rounded-t-lg">
                            <img src={el.src} alt={el.name} className="md:h-[40vh] sm:h-[30vh] min-h-[20vh] w-full object-cover rounded-t-2xl transition-transform duration-500 ease-in-out transform hover:xl:skew-y-[20deg]" />
                        </div>
                        <div className="flex justify-between items-center p-2 sm:p-3">
                            <h3 className="text-lg font-semibold text-white">{el.name}</h3>
                            <button onClick={() => navigate(el.href)} className="bg-black hover:bg-white text-red-500 hover:text-red-500 font-semibold py-2 px-4 rounded-lg">
                                <BiRightArrowAlt className="mr-1" />
                            </button>
                        </div>
                        <p className="text-white px-2 sm:px-3 pb-4">{el.about}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
