import React from 'react';
import { Link } from 'react-router-dom';
import AanyaHomesHead from '../../assets/Aanya-homes-head.png'

const BlogTags = (props) => {
    const { mt = 0, tags } = props;

    return (
        <div className={`mt-${mt}`}>
            {tags.map((tag, index) => (
                <span key={index} className="inline-block bg-orange-500 rounded-md px-2 py-1 mr-2 text-sm font-medium text-white">{tag}</span>
            ))}
        </div>
    );
};

const BlogAuthor = ({date}) => {
    return (
        <div className="flex items-center mt-2 space-x-2">
            {/* https://100k-faces.glitch.me/random-image */}
            <img src={AanyaHomesHead} alt={`Avatar of Head`} className="rounded-full w-10 h-10"/>
            <span className="font-medium">Mr. Shiva Goswami</span>
            <span className="font-normal">—</span>
            <span>{new Date(date).toLocaleDateString()}</span>
        </div>
    );
};

export const BlogMain = ({ blog, isInside = false }) => {
    return (
        <div className="flex flex-col lg:flex-row mt-5">
            <div className="relative lg:w-3/5">
                <div className="w-full lg:w-4/5"><img src={blog.photo} alt="blog post" className="rounded-lg w-[-webkit-fill-available]"/></div>
            </div>
            <div className="flex flex-col justify-center mt-3 lg:mt-0 lg:w-2/5 text-white">
                <BlogTags tags={['Best Property', 'Investment']} mt={1} />
                <h2 className="mt-1 text-xl lg:text-2xl font-semibold text-white">
                    <Link to={`/blogs/${blog.id}`} className="hover:underline">{blog.title}</Link>
                </h2>
                <p className="mt-2 text-lg">{blog.details}</p>
                <div className="flex justify-between items-center mt-2">
                    <BlogAuthor date={blog.date} />
                    {!isInside && (
                        <Link to={`/blogs/${blog.id}`} className="px-4 py-2 text-sm font-semibold rounded-full bg-white hover:text-orange-500 text-gray-900">
                            Read More...
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

