import React from 'react';
import './App.css';
import { AllRoutes } from './Routes/AllRoutes';
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <>
      <Analytics />
      <AllRoutes />
    </>
  )
}

export default App;
