import React from 'react';
import rahulKumar from '../../assets/testimonials/rahul-kumar.png';
import soniaGupta from '../../assets/testimonials/sonia-gupta2.png';
import amitSharma from '../../assets/testimonials/amit-sharma.png';

const userTestimonials = [
    {
        avatar: rahulKumar,
        name: 'Rahul Kumar',
        testimonial: "It was the great decision of my life to found this company, G.A.H Aanya Homes Pvt Ltd has been instrumental in helping me find my dream home. Their dedication to quality and customer satisfaction is truly commendable. I'm grateful for their professionalism and support throughout the entire process.",
    },
    {
        avatar: soniaGupta,
        name: 'Sonia Gupta',
        testimonial: "As a first-time homebuyer, I was overwhelmed by the process, but G.A.H Aanya Homes made it seamless and stress-free. From guiding me through property selection to assisting with paperwork, they went above and beyond to ensure I found the perfect home. I highly recommend their services to anyone in the market for a new property.",
    },
    {
        avatar: amitSharma,
        name: 'Amit Sharma',
        testimonial: "I had the pleasure of working with G.A.H Aanya Homes for my investment property, and I couldn't be happier with the results. Their in-depth knowledge of the real estate market, coupled with their personalized approach, helped me secure a lucrative deal. Thanks to their expertise, I've seen significant returns on my investment.",
    }
];

const logos = [
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg'
];

export const Testimonials = () => {
    return (
        <div className="my-[4vw] flex flex-col items-center text-white gap-8">
            <div className="w-full text-center">
                <h2 className="font-semibold text-2xl lg:text-3xl text-white mb-4">Client's Testimonials</h2>
                <p className="text-lg lg:w-full m-auto">See what our customers love about our products. Discover how we excel in efficiency, durability, and satisfaction. Join us for quality, innovation, and reliable support.</p>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-4">
                {userTestimonials.map((testimonial, index) => (
                    <div key={index} className="p-4 rounded-md bg-[#1e1e1e] shadow shadow-[0_3px_8px_grey] hover:shadow-[0_3px_8px_white]">
                        <p className="text-md bg-[#1e1e1e]">{testimonial.testimonial}</p>
                        <div className="flex justify-between mt-4 bg-[#1e1e1e]">
                            <div className="flex items-center bg-[#1e1e1e]">
                                <img src={testimonial.avatar} className="w-10 h-10 rounded-full" alt={`Avatar ${index}`} />
                                <div className="ml-2">
                                    <p className="font-semibold bg-[#1e1e1e]">{testimonial.name}</p>
                                </div>
                            </div>
                            <img src={logos[index]} className="w-10 opacity-30 bg-white" alt={`Logo ${index}`} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}