import React from 'react';
import { BubbleText } from './BubbleText';
import { Link, useLocation} from 'react-router-dom';

const NAV_ITEMS = [
    { label: 'ABOUT US', href: '/about' },
    { label: 'OUR PROJECTS', href: '/our-projects' },
    { label: 'BLOGS', href: '/blogs' },
    { label: 'CONTACT US', href: '/contact' },
    { label: 'CAREER', href: '/career' },
    { label: 'PRIVACY NOTICE', href: '/privacy-notice' }
];

export default function Navbar() {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggleMenu = () =>(setIsOpen(!isOpen));

    return (
        <div className="sticky top-0 z-50">
            <nav className="w-[85vw] mx-[auto] flex items-center justify-between border-b border-gray-200 px-6 sm:px-5 py-4 sm:py-6">
                <div className="flex items-center">
                    <button onClick={toggleMenu} className="block md:hidden text-white focus:outline-none mr-2 md:mr-10 sm:mr-10">
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isOpen?"M6 18L18 6M6 6l12 12":"M4 6h16M4 12h16m-7 6h7"}/>
                        </svg>
                    </button>
                    <Link to='/' className="font-bold" ><BubbleText fontSize='36px' text={'G.A.H Aanya Homes Pvt.Ltd'}/></Link>
                </div>
                <div className="hidden md:flex lg:flex xl:flex 2xl:flex"><DesktopNav /></div>
            </nav>
            {isOpen && <MobileNav />}
            <hr/>
        </div>
    );
}

const DesktopNav = () => {
    return (
        <ul className="flex items-center space-x-4">
            {NAV_ITEMS.map((navItem)=>(<li key={navItem.label}><Link to={navItem.href} className="text-white hover:text-[#fce364] md:text-[11px] lg:text-base">{navItem.label}</Link></li>))}
        </ul>
    );
};

const MobileNav = () => {
    const path = useLocation().pathname;

    const MobileNavItem = ({ label,href }) => {
        return (
            <div className="mb-4">
                <Link to={href} className={`flex items-center justify-between md:text-base sm:text-sm w-full text-white font-semibold ${path===href?'bg-gray-900':'bg-dark'}`}>{label}</Link>
            </div>
        );
    };
    
    return (
        <div className="md:hidden p-4">
            {NAV_ITEMS.map((navItem) => (<MobileNavItem key={navItem.label} {...navItem} />))}
        </div>
    );
};