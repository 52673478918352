import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import cyberthumBanner from '../../assets/projects/banners/cyberthum.png'
import citycenter150Banner from '../../assets/projects/banners/citycenter150.png'
import avenue62Banner from '../../assets/projects/banners/avenue62.png'
import fairfoxBanner from '../../assets/projects/banners/fairfox.png'
import galleria99Banner from '../../assets/projects/banners/galleria99.png'
import kailasaBanner from '../../assets/projects/banners/kailasa.png'
import yugenBanner from '../../assets/projects/banners/yugen.png'
import gulfOfGoaBanner from '../../assets/projects/banners/gulf-of-goa.png'
import migsunRohiniCentralBanner from '../../assets/projects/banners/migsun-rohini-central.png'
import sawasdeeHeightsBanner from '../../assets/projects/banners/sawasdee-heights.png'
import sawasdeeNspBanner from '../../assets/projects/banners/sawasdee-nsp.png'
import signatureGlobalCity79BBanner from '../../assets/projects/banners/signature-global-city-79B.png'
import theCullinanBanner from '../../assets/projects/banners/the-cullinan.png'
import theLineBanner from '../../assets/projects/banners/the-line.png'

const squareData = [
    { id: 1, src: cyberthumBanner },
    { id: 2, src: citycenter150Banner },
    { id: 3, src: avenue62Banner },
    { id: 4, src: fairfoxBanner },
    { id: 5, src: galleria99Banner },
    { id: 6, src: kailasaBanner },
    { id: 7, src: yugenBanner },
    { id: 8, src: gulfOfGoaBanner },
    { id: 9, src: migsunRohiniCentralBanner },
    { id: 10, src: sawasdeeHeightsBanner },
    { id: 11, src: sawasdeeNspBanner },
    { id: 12, src: signatureGlobalCity79BBanner },
    { id: 13, src: theCullinanBanner },
    { id: 14, src: theLineBanner },
    { id: 15, src: avenue62Banner },
    { id: 16, src: fairfoxBanner }
];

const shuffle = (array) => {
    let currentIndex = array.length, randomIndex;

    while (currentIndex !==0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex],
        ];
    }

    return array;
};

const generateSquares = () => (shuffle(squareData).map(sq => <motion.div key={sq.id} layout transition={{ duration: 1.5, type: "spring" }} className="w-full h-full" style={{ backgroundImage: `url(${sq.src})`, backgroundSize: "cover" }} />));

export const ShuffleHero = () => {
    const timeoutRef = React.useRef(null);
    const [squares, setSquares] = React.useState(generateSquares());

    React.useEffect(() => {
        shuffleSquares();
        return () => clearTimeout(timeoutRef.current);
    }, []);

    const shuffleSquares = () => {
        setSquares(generateSquares());
        timeoutRef.current = setTimeout(shuffleSquares, 3000);
    };

    return (
        <section className="grid grid-cols-1 md:grid-cols-2 items-center gap-8 w-[82vw] mx-auto my-12">
            <div>
                <span className="block mb-4 text-xs md:text-sm text-indigo-500 font-medium">Find Your Dream Property</span>
                <h3 className="text-4xl md:text-6xl text-white font-semibold">Discover Your Ideal Home</h3>
                <p className="text-base md:text-lg text-white my-4 md:my-6">Explore a wide range of properties tailored to your needs and preferences. Whether you're looking for a cozy apartment, a spacious family home, or a luxurious villa, we have something for everyone.</p>
                <Link to={'/contact'} className="bg-indigo-500 text-white font-medium py-2 px-4 rounded transition-all hover:bg-indigo-600 active:scale-95">Contact Us</Link>
            </div>
            <div className="grid grid-cols-4 grid-rows-4 h-[450px] gap-1">{squares.map((sq) => sq)}</div>
        </section>
    );
};