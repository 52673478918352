import React from 'react'
import  bhutaniInfraLogo from '../../assets/companies/logos/bhutani-infra.png'
import  galaxyLogo from '../../assets/companies/logos/galaxy.png'
import  eonLogo from '../../assets/companies/logos/eon.png'
import  yugenInfraLogo from '../../assets/companies/logos/yugen-infra.png'
import  signatureGlobalLogo from '../../assets/companies/logos/signature-global.png'
import  ocusMedleyLogo from '../../assets/companies/logos/ocus.png'
import  tarcLogo from '../../assets/companies/logos/tarc.png'
import  migsunLogo from '../../assets/companies/logos/migsun.png'
import  m3mLogo from '../../assets/companies/logos/m3m.png'
import  ayodhyaAbhinandanLogo from '../../assets/companies/logos/ayodhya-abhinandan.png'

const Brands = () => {
  const partners = [bhutaniInfraLogo,galaxyLogo,eonLogo,yugenInfraLogo,signatureGlobalLogo,ocusMedleyLogo,tarcLogo,migsunLogo,m3mLogo,ayodhyaAbhinandanLogo]

  return (
    <div className="w-[82vw] my-[4vw] m-auto">
      <h2 className="font-semibold text-center text-2xl lg:text-3xl text-white mb-4">Brands on Aanya Homes</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-8 p-5">
        {partners?.map((el, index) => (<img key={index} src={el} className="w-40 md:w-48 h-24 md:h-32 bg-gray-300 rounded-md" alt="company" />))}
      </div>
    </div>
  )
}

export default Brands