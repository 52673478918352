import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import cyberthumBanner from '../assets/projects/banners/cyberthum.png'
import citycenter150Banner from '../assets/projects/banners/citycenter150.png'
import avenue62Banner from '../assets/projects/banners/avenue62.png'
import fairfoxBanner from '../assets/projects/banners/fairfox.png'
import galleria99Banner from '../assets/projects/banners/galleria99.png'
import kailasaBanner from '../assets/projects/banners/kailasa.png'
import yugenBanner from '../assets/projects/banners/yugen.png'
import gulfOfGoaBanner from '../assets/projects/banners/gulf-of-goa.png'
import migsunRohiniCentralBanner from '../assets/projects/banners/migsun-rohini-central.png'
import sawasdeeHeightsBanner from '../assets/projects/banners/sawasdee-heights.png'
import sawasdeeNspBanner from '../assets/projects/banners/sawasdee-nsp.png'
import sawasdeeGemsBanner from '../assets/projects/banners/sawasdee-gems.png'
import signatureGlobalCity79BBanner from '../assets/projects/banners/signature-global-city-79B.png'
import theCullinanBanner from '../assets/projects/banners/the-cullinan.png'
import theLineBanner from '../assets/projects/banners/the-line.png'

import cyberthumLogo from '../assets/projects/logos/cybertherm.png'
import avenue62Logo from '../assets/projects/logos/avenue62.png'
import citycenter150Logo from '../assets/projects/logos/citycenter150.png'
import sawasdeeHeightsLogo from '../assets/projects/logos/sawasdeeheights.png'
import sawasdeeNspLogo from '../assets/projects/logos/sawasdeensp.png'
import sawasdeeGemsLogo from '../assets/projects/logos/sawasdeeGems.png'
import fairfoxLogo from '../assets/projects/logos/fairfox.png'
import yugenLogo from '../assets/projects/logos/yugen.png'
import signatureGlobalCity79BLogo from '../assets/projects/logos/signatureGlobalCity79B.png'
import galleria99Logo from '../assets/projects/logos/galleria99.png'
import kailasaLogo from '../assets/projects/logos/kailasa.png'
import migsunRohiniCentralLogo from '../assets/projects/logos/migsun-rohini-central.png'
import theCullinanLogo from '../assets/projects/logos/the-cullinan.png'
import theLineLogo from '../assets/projects/logos/the-line.png'
import gulfOfGoaLogo from '../assets/projects/logos/gulf-of-goa.png'

export const Project = () => {
    const path = useLocation().pathname.split('projects/')[1];
    const [dataState, setDataState] = React.useState({});
    const [projectLink, setProjectLink] = React.useState('');

    const data = [
        {
            name: "cyberthum",
            banner: cyberthumBanner,
            logo: cyberthumLogo,
            heading: "Leap Into The Future",
            p: [
                "Standing at the cutting edge of technology, Cyberthum is a 26.8-acre mixed-use complex. Cyberthum offers commercial property in Noida which will set the precedent and definition for what tomorrow's landmarks will be built on. Cyberthum is home to one of India’s largest gaming and entertainment zones, world-class workspaces and co-working spaces, retail spaces, and a mall in the sky. A staggering 46-storey high commercial, Cyberthum is a work-life space made for you, with your needs and lifestyles in mind.",
                "CYBERTHUM RERA NO. PHASE A: UPRERAPRJ236478, CYBERTHUM PHASE B: UPRERAPRJ240015, CYBERTHUM PHASE E: UPRERAPRJ190938, CYBERTHUM PHASE H: UPRERAPRJ674006, Phase – II UPRERAPRJ452606."
            ]
        },
        {
            name: "avenue62",
            banner: avenue62Banner,
            logo: avenue62Logo,
            heading: "One Of The Fastest Developing Urban Centers",
            p: [
                "The future-ready retail space will offer a wider experience than just physical shops but rather places in which to meet and engage with each other, be entertained, and try out new products and experiences. These multidimensional retail centers are poised to become a meeting point for kindred spirits, leading to unexpected and memorable encounters. And that is what Avenue 62 is all about.",
                "Avenue 62 is the perfect amalgamation of an architectural form and a work of art. The singular seamless perforated roof with its wraparound latticed skin and distinctive flowing lines gives it an organic texture that undulates with the land and is in sharp contrast to the boxy commercial spaces the world has been exposed to. The distinctive structure with its lack of sharp angles is seemingly supported at the entrance by a breathtakingly tall waterfall that elegantly invites the world in.",
                "NOIDA CYBER HUB - RERA No. UPRERAPRJ335841"
            ]
        },
        {
            name: "citycenter150",
            banner: citycenter150Banner,
            logo: citycenter150Logo,
            heading: "The New Landmark Of Noida Retail",
            p: [
                "City Center 150 is the new address for experiential retail. It offers a glimpse into the future of lifestyle leisure and retail areas in Delhi NCR with high-street shopping in an al fresco style in a low-rise development. Built to immerse visitors in a world of seamless convenience. Bhutani City Center 150 has something for everyone, making it a recreational nirvana. The fine dining destination and entertainment choices make this recreational nirvana complete. It is a place for leisure activities where you can relax outside and take in the moment.",
                "With a 500+ foot (158 metres) frontage, a contemporary glass façade and clean graphical lines forming intriguing patterns, Bhutani City Center 150 announces itself in the grandest possible way.",
                "On the banks of a 30 m wide road, City Center 150's design invites people to indulge in retail therapy. While providing brands with glittering retail spaces in Noida to stake their claim in the hearts and minds of the consumers. And if that doesn't make you gasp for air, the park views that greet diners at the numerous F&B establishments scattered across the food court space in Noida will offer you another excuse to halt and take it all in.",
                "BHUTANI CITY CENTER 150 RERA NO. UPRERAPRJ270081"
            ]
        },
        {
            name: "sawasdeeHeights",
            banner: sawasdeeHeightsBanner,
            logo: sawasdeeHeightsLogo,
            heading: "Sawasdee Heights Ultra Luxurious",
            p: [
                "Sawasdee Heights is a residential project strategically located in the adjoining prestigious areas of Punjabi Bagh and Pitampura, Delhi. It will comprise of 8.5 lac sq ft saleable area and consist of all modern amenities. Amenities include amusement areas such as kids play area, gymnasium, swimming pools on the podium level which is free from any vehicular movement, a club house and a commercial plaza.",
                "The architecture will feature a contemporary façade with balconies encompassing seamless glass railing with steel handrail and neatly finished mesh coverings for the cutouts. Project will be constructed using mivan shuttering to encourage sustainability practices and provide the best quality of finishing.",
                "In terms of accessibility, site is walking distance from the ring road and the Shakurpur Metro station whilst the commercial hub of NSP is 1 km away."
            ]
        },
        {
            name: "sawasdeeNsp",
            banner: sawasdeeNspBanner,
            logo: sawasdeeNspLogo,
            heading: "Building Dreams Since 2008",
            p: [
                "Netaji Subash Palace (NSP), has overtime become the hub for commercial and office spaces in Delhi Sawasdee NSP is a significant commercial development within NSP which will be developed with the best of facilities.",
                "It is designed and inspired by modern architecture, hence will comprise of a contemporary façade and elevation. It is also equipped with a combination of retail and office spaces, along with vast parking spaces, modern air conditioning facilities, clean and hygiene friendly toilets, well-spaced and lit corridors etc.",
                "The Location of the development is at the centre of the entire district, and is surrounded by the DDA park (backside), other buildings and 9 mtr road(front). It is 0.5 km from NSP Metro Station, 0.2 km from NSP bus stand, 0.5 km from Delhi haat. and therefore, has excellent connectivity and accessibility"
            ]
        },
        {
            name: "sawasdeeGems",
            banner: sawasdeeGemsBanner,
            logo: sawasdeeGemsLogo,
            heading: "Expansion Always, In All Ways",
            p: [
                "Sawasdee Gems is a retail & office development that will consist of the best of facilities. Sawasdee Gems Delhi is inspired from modern architecture, and therefore will comprise of a contemporary façade which will be sleek and appealing in nature. The 2.2 lac sq. Ft. mall will entail retail shops, a food court and restaurants along with a 4 screen multiplex which has already been tied up with Kanakia Cinemas. Furthermore, there will be office spaces from the 7th floor up till the 16th floor. ",
                "The mall finishes will be of a high quality using durable and sustainable material and a large atrium area allowing for maximum natural light. Sawasdee Gems Commercial Spaces Amenities include of modern restroom facilities, sophisticated security controls and CCTV surveillance and plentiful of parking spaces.",
                "This Sawasdee project in Delhi have a diverse mix of Retail Shops, Office Spaces, Food Courts, Exquisite Restaurants, and a 4-Screen Multiplex for Endless Entertainment."
            ]
        },
        {
            name: "fairfox",
            banner: fairfoxBanner,
            logo: fairfoxLogo,
            heading: "Project Of Today And Tommorow",
            p: [
                "Fairfox IT infra, Simple philosophy is based on assuring Quality Construction, Timely Delivery, Corporates based Themes and Retail Friendly Environment and Hassle Free Transactions.",
                "Our every project will be deliver with world-class design and uncompromised quality, and will be a star against the higheststandards of service",
                "Ethical business processes and timely execution of our tasks makes us a trustworthy and dependable name in the Indian real estate industry.",
                "Believers. Put our 100%, beat construction, design, quality, development, investment, technology, client servicing and tenancy with Decent ROI"
            ]
        },
        {
            name: "yugen",
            banner: yugenBanner,
            logo: yugenLogo,
            heading: "Best Indian Real Estate Industry",
            p: [
                "We at Yugen Infra plan to delight Indians with luxurious yet affordable properties. Yugen Infra is a joint venture of Timespro Consulting LLP and V K Developers Pvt Ltd and we have forayed into the burgeoning real estate market of the country with a grand residential project near New Goa International Airport, also known as MOPA.",
                "Yugen Infra has a committed projection figure of an estimated of 10,000 Cr in the next 3 years. Apart from this project of significant multitude, we are also coming up with many mega projects in Delhi-NCR, where Yugen’s headquarters is based."
            ]
        },
        {
            name: "signatureGlobalCity79B",
            banner: signatureGlobalCity79BBanner,
            logo: signatureGlobalCity79BLogo,
            heading: "Interior Styling By Sonali Bhagwati",
            p: [
                "Welcome to Signature Global CITY 79B. Set amidst the Aravallis and surrounded by vast acres of verdant forest, it builds an urban niche into the heart of nature; away from the hustle and bustle of the city.",
                "An apartment complex that is an experience unlike any other. Something that is steeped in modern design yet very much rooted in nature. ",
                "Stands to reason, it creates an exclusive ambience that echoes with ageless folklore of the Aravallis, yet very much contemporary in its approach to living and amenities. An epitome of premium individuality, enjoy your own independent space, where your dream home becomes a reality in many different ways."
            ]
        },
        {
            name: "galleria99",
            banner: galleria99Banner,
            logo: galleria99Logo,
            heading: "A Never Seen Retail Format On Dwarka Expressway",
            p: [
                "Part of a mega high-street improvement bang on Dwarka Expressway – Gurugram. The Retail Shops in Ocus Medley 99 is a favourite for brands imparting them a high-street retail experience, multiplex, food court, restaurants, and offices. Densely populated sectors of the metropolis surround it.",
                "Located in Sector 99, Dwarka Expressway, OCUS Medley 99 Gurgaon is one of the most widespread business projects from the OCUS group. Covering a huge location of round 4.14 acres, this undertaking will include high-street retail and a lofty corporate tower. OCUS Medley 99 is a strategic location, exquisite architectural design, and top-notch amenities make it one of the most popular industrial tasks on board."
            ]
        },
        {
            name: "kailasa",
            banner: kailasaBanner,
            logo: kailasaLogo,
            heading: "Experience The Pinnacle Of Luxury Living",
            p: [
                "TARC Kailāsa is located iconically on Patel Road. Patel Road serves as the main artery to Central Delhi, ensuring effortless access to all major destinations. Experience the refreshing embrace of nature, just a stone's throw away from the legendary Delhi Ridge.",
                "Embark on an exclusive opportunity to acquire opulent residences in the heart of Kirti Nagar, New Delhi. This prestigious development comprises five towering structures, with two housing expansive 4BHK apartments and three offering stylish 3BHK residences. Spanning approximately 7 acres, each tower stands as a testament to architectural grandeur, providing residents with breathtaking panoramic views.",
                "At the pinnacle of luxury, a sprawling club area covering 1.70 lakh sqft awaits, featuring world-class amenities such as a 50-meter heated pool, indoor pool, bowling alley, lavish spa, well-appointed rooms, and a double theatre, among others.",
                "Indulge in a lifestyle of extravagance with an array of amenities, including a lounge, swimming pool, kids' play area, clubhouse, gym, water bodies, yoga room, café, organic garden, and an advanced air purification system. Immerse yourself in the epitome of luxury at TARC Kailasa, offering an unparalleled living experience."
            ]
        },
        {
            name: "migsunRohiniCentral",
            banner: migsunRohiniCentralBanner,
            logo: migsunRohiniCentralLogo,
            heading: "The Center of the Shopper",
            p: [
                "Nestled in the vibrant city of Delhi, Migsun Rohini Central stands as a prominent high-street commercial project developed by the prestigious Migsun Group. Spanning approximately 9 acres of prime land, this project redefines the commercial retail spaces in Delhi-NCR with its strategic location and innovative design.",
                "Migsun Rohini Central is more than just a commercial project; it’s a visionary development that caters to the diverse needs of businesses and consumers alike. With meticulous planning and execution, the project reflects the signature style and commitment to excellence that the Migsun Group is known for."
            ]
        },
        {
            name: "theCullinan",
            banner: theCullinanBanner,
            logo: theCullinanLogo,
            heading: "A One-In-A-Million Lifestyle Experience",
            p: [
                "M3M The Cullinan is a residential project developed by M3M Group. It is located in Sector 94, Noida Expressway, Noida. M3M The Cullinan offers ultra luxury residential apartments designed to provide a luxurious and comfortable living experience.",
                "The project features spacious and well-designed apartments with modern architecture and premium amenities. The apartments are available in different configurations to cater to the varying needs of residents. M3M The Cullinan offers a range of amenities such as a swimming pool, fitness center, landscaped gardens, children's play area, clubhouse, and 24/7 security.",
                "Additionally, the location of M3M The Cullinan in Sector 94, Noida, provides easy access to major roads, commercial areas, educational institutions, healthcare facilities, and entertainment centers. It is well-connected to other parts of the city, including the Yamuna Expressway.",
            ]
        },
        {
            name: "theLine",
            banner: theLineBanner,
            logo: theLineLogo,
            heading: "Premium Retails Shops & Studio Apartments",
            p: [
                "M3M The LINE stands tall as a testament to opulence, opportunity and growth. This premium commercial real estate project in Noida Sector 72 is a symphony of state-of-the-art architecture, strategic location, and seamless connectivity making it as the best premium investment opportunity in Noida.",
                "M3M The LINE caters to the dynamic needs of modern businesses with retail shops, restaurants, pent suites, and showrooms. Located in one of the fastest-growing commercial hubs of the region, it provides an environment where businesses thrive amidst a bustling hub of commerce and activities which this ever-developing region is."
            ]
        },
        {
            name: "gulfOfGoa",
            banner: gulfOfGoaBanner,
            logo: gulfOfGoaLogo,
            heading: "Way Of Life",
            p: [
                "The prestigious shores have hosted a symphony of blockbusters, with the likes of Goa Marriot Hotel setting the stage for countless silver screen memories. Adorned with the crowning jewels of luxury retreats like Taj Convention Centre and Grand Hyatt, this coastal haven beckons, offering a gateway to opulent living and unending bliss. Launching Gulf of Goa, Goa's Flagship Coastal Villa Estates at India's most celebrated shorelines.",
                "The smallest state in India, yet has the highest GDP per capita and home to India’s 1st printing press, as well as the country’s 1st Casino.",
                "Rated amongst the best beaches in Asia #1 Agonda Beach, #2 Cavellosim Beach, #8 Palolem Beach and #16 Benaulim Beach"
            ]
        }
    ];

    const getProjectLink = () => {
        switch (path) {
            case 'cyberthum':
                setProjectLink('https://drive.google.com/file/d/15AoUpdS80NIHetusYISY7EvMTO5mqeY8/view?usp=sharing');
                break;
            case 'avenue62':
                setProjectLink('https://drive.google.com/file/d/1H0XGJ2ebce78jSuLBRhTqXx6CRsbNlHd/view?usp=sharing');
                break;
            case 'citycenter150':
                setProjectLink('https://drive.google.com/file/d/1y-f5jS8Zh0XPRxH9odtgUXYAbBPRjXCZ/view?usp=sharing');
                break;
            case 'sawasdeeHeights':
                setProjectLink('https://drive.google.com/file/d/1CMrjpLlkpf3v7kyiZAWQCoBpm2gqDsDu/view?usp=sharing');
                break;
            case 'sawasdeeNsp':
                setProjectLink('https://drive.google.com/file/d/1TD_dracCWT-_IhbOcu_kvf48dEibhY3z/view?usp=sharing');
                break;
            case 'sawasdeeGems':
                setProjectLink('https://drive.google.com/file/d/18b_-8iEKnm3kZPovXuDpRPzqzCVMjJYt/view?usp=sharing');
                break;
            case 'fairfox':
                setProjectLink('https://drive.google.com/file/d/1CxHARTjw3TkweCB-qFOYUap1KQtcJyvX/view?usp=sharing');
                break;
            case 'yugen':
                setProjectLink('https://drive.google.com/file/d/1uvWiSF5BT3pT5LRO5frrghPpuva3hHW3/view?usp=sharing');
                break;
            case 'signature-global-city-79B':
                setProjectLink('https://drive.google.com/file/d/1yCkw4trNNUGhFZdSlGbALT0btEnCYuAW/view?usp=sharing');
                break;
            case 'galleria99':
                setProjectLink('https://drive.google.com/file/d/18nz7F3xNiHGoyZpiyetmdnwaMfwXR42H/view?usp=sharing');
                break;
            case 'kailasa':
                setProjectLink('https://drive.google.com/file/d/18nz7F3xNiHGoyZpiyetmdnwaMfwXR42H/view?usp=sharing');
                break;
            case 'migsunRohiniCentral':
                setProjectLink('https://drive.google.com/file/d/1Hi2VKCUFMygIB8I016JN0ysNV7ydh1bw/view?usp=sharing');
                break;
            case 'theCullinan':
                setProjectLink('https://drive.google.com/file/d/1EXvSs95SgkIvuCiaI2vSWGyMFce6sV5r/view?usp=sharing');
                break;
            case 'theLine':
                setProjectLink('https://drive.google.com/file/d/1uzYOTQLYm6ZGRUyXjZr4hwI2I9wcPMgl/view?usp=sharing');
                break;
            case 'gulfOfGoa':
                setProjectLink('https://drive.google.com/file/d/1eRqfYmrPLZNQ7soFt3HsH3W_4dpUptW5/view?usp=sharing');
                break;
            default:
                setProjectLink('');
        }
    }

    React.useEffect(() => {
        const d = data.find((el) => el.name === path);
        if (d) setDataState(d);
        getProjectLink();
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <img src={dataState?.banner} className="w-full h-[40vh] sm:h-[50vh] md:h-[50vh] lg:h-[66vh] min-h-[20vh]" alt={'cyberthum'} />
            <img src={dataState?.logo} className="w-[22%] mx-auto my-10" alt={'cyberthum'} />
            <div className="w-[80vw] mx-auto text-white">
                <h1 className="text-xl md:text-3xl lg:text-2xl xl:text-3xl font-semibold my-10 text-white">{dataState?.heading}</h1>
                <div className="space-y-4 md:space-y-6 lg:space-y-8 xl:space-y-10 mb-20">
                    {dataState?.p?.map((p, index) => <p key={index} className="lg:text-lg md:text-base sm:text-base xs:text-base">{p}</p>)}
                </div>
                <div className="m-20 auto text-center">
                    <button className="rounded-lg p-4 w-fit text-[12px] lg:text-base bg-red-500 text-white font-bold hover:bg-red-700">
                        <Link to={projectLink} target='_blank' className='bg-transparent'>Click Here To See BROCHURE</Link>
                    </button>
                </div>
            </div>
        </div>
    )
}