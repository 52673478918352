import React from 'react'
import { Link } from 'react-router-dom';
import { IoMdMailOpen } from 'react-icons/io'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaPhoneAlt, FaTwitter, FaYoutube, FaWhatsapp } from "react-icons/fa";

const links = [
  [
    { href: 'tel:7027070207', icon: 'FaPhoneAlt', color: 'cyan' },
    { href: 'mailto:aanyahomes@gmail.com', icon: 'IoMdMailOpen', color: '#55acee' },
    { href: 'https://wa.me/7027070207', icon: 'FaWhatsapp', color: 'teal' }
  ],
  [
    { href: 'https://www.facebook.com/profile?id=61553741175035&mibextid=LQQJ4d', icon: 'FaFacebookF', color: '#3b5999' },
    { href: 'https://www.instagram.com/aanyahomes.pvt.ltd', icon: 'FaInstagram', color: '#e4405f' },
    { href: 'https://www.youtube.com/@GAH.Pvt.Ltd', icon: 'FaYoutube', color: '#e4405f' }
  ]
]

export const SocialHandles = () => {
  // Map each icon name to its corresponding React icon component
  const iconComponents = {
    FaFacebookF: FaFacebookF,
    FaInstagram: FaInstagram,
    FaLinkedinIn: FaLinkedinIn,
    FaTwitter: FaTwitter,
    FaYoutube: FaYoutube,
    FaPhoneAlt: FaPhoneAlt,
    IoMdMailOpen: IoMdMailOpen,
    FaWhatsapp: FaWhatsapp
  };

  return (
    <>
      <div className={`flex flex-col flex-wrap items-center bg-transparent px-2 py-4 fixed sm:top-[25vh] top-[30vh] gap-[1vw] w-fit rounded-[10px]`}>
        {links[0].map(link => {
          const IconComponent = iconComponents[link.icon];
          return <Link key={link.icon} to={link.href} target='_self' className={`w-10 h-10 flex justify-center items-center bg-white rounded-full`}>
            <IconComponent style={{ backgroundColor: 'transparent', color: link.color, height: '15px', width: '15px' }} />
          </Link>;
        })}
      </div>
      <div className={`flex flex-col flex-wrap items-center bg-transparent px-2 py-4 fixed sm:top-[25vh] top-[30vh] right-1 gap-[1vw] w-fit rounded-[10px]`}>
        {links[1].map(link => {
          const IconComponent = iconComponents[link.icon];
          return <Link key={link.icon} to={link.href} target='_blank' className={`w-10 h-10 flex justify-center items-center bg-white rounded-full`}>
            <IconComponent style={{ backgroundColor: 'transparent', color: link.color, height: '15px', width: '15px' }} />
          </Link>;
        })}
      </div>
    </>
  )
}
