import React from "react";
import styles from "../Styles/BubbleText.module.css";

export const BubbleText = ({text}) => {
    return (
        <div className="grid place-content-center bg-black">
            <h2 className={`text-center text-xl lg-4xl sm:text-2xl font-thin font-serif`} style={{fontFamily: 'Times New Roman'}}>
                {text.split("").map((child,idx) => (<span className={styles.hoverText} key={idx}>{child}</span>))}
            </h2>
        </div>
    );
};