import React from 'react'
import ItemsCarousel from './../Components/HomepageComp/ItemsCarousel';
import Brands from '../Components/HomepageComp/Brands';
import { CompaniesWtUs } from '../Components/HomepageComp/CompaniesWtUs';
import { About } from './About';
import logoGif from '../assets/logoGif.gif';
import { ShuffleHero } from '../Components/HomepageComp/ShuffleHero';

export const Homepage = () => {
  return (
    <div>
      <img src={logoGif} alt='logo gif' className="w-full h-full object-cover"/>
      <ShuffleHero/>
      <CompaniesWtUs/>
      <ItemsCarousel />
      <About/>
      <Brands/>
    </div>
  )
}
