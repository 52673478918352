import React from "react";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import  bhutaniInfraLogo from '../../assets/companies/logos/bhutani-infra.png'
import  galaxyLogo from '../../assets/companies/logos/galaxy.png'
import  eonLogo from '../../assets/companies/logos/eon.png'
import  yugenInfraLogo from '../../assets/companies/logos/yugen-infra.png'
import  signatureGlobalLogo from '../../assets/companies/logos/signature-global.png'
import  ocusMedleyLogo from '../../assets/companies/logos/ocus.png'
import  tarcLogo from '../../assets/companies/logos/tarc.png'
import  migsunLogo from '../../assets/companies/logos/migsun.png'
import  m3mLogo from '../../assets/companies/logos/m3m.png'
import  ayodhyaAbhinandanLogo from '../../assets/companies/logos/ayodhya-abhinandan.png'

const data = [
    [
        {
            name: "Bhutani Infra",
            href: '/companies/BhutaniInfraNoida',
            src: bhutaniInfraLogo,
            about: "Standing at the cutting edge of technology, Cyberthum is a 26.8 acre mixed-use complex and offers commercial property in Noida..."
        },
        {
            name: "Galaxy",
            href: '/companies/Galaxy',
            src: galaxyLogo,
            about: 'The future-ready retail space will offer a wider experience than just physical shops but rather places in which to meet and enage wit...'
        },
        {
            name: "EON - Eye of Noida",
            href: "/companies/EON",
            src: eonLogo,
            about: "City Center 150 is the new address for experimential retail. It offers a glimpse into the future of listyle and retail areas..."
        }
    ],
    [
        {
            name: "Yugen Infra",
            href: "/companies/YugenInfra",
            src: yugenInfraLogo,
            about: "City Center 150 is the new address for experimential retail. It offers a glimpse into the future of listyle and retail areas..."
        },
        {
            name: "Signature Global",
            href: '/companies/SignatureGlobal',
            src: signatureGlobalLogo,
            about: "Standing at the cutting edge of technology, Cyberthum is a 26.8 acre mixed-use complex and offers commercial property in Noida..."
        },
        {
            name: "Ocus Medley",
            href: '/companies/OCUSMedley',
            src: ocusMedleyLogo,
            about: 'The future-ready retail space will offer a wider experience than just physical shops but rather places in which to meet and enage wit...'
        },

    ],
    [
        {
            name: "Tarc",
            href: "/companies/Tarc",
            src: tarcLogo,
            about: "City Center 150 is the new address for experimential retail. It offers a glimpse into the future of listyle and retail areas..."
        },
        {
            name: "Migsun",
            href: '/companies/Migsun',
            src: migsunLogo,
            about: "Standing at the cutting edge of technology, Cyberthum is a 26.8 acre mixed-use complex and offers commercial property in Noida..."
        },
        {
            name: "m3m",
            href: '/companies/M3M',
            src: m3mLogo,
            about: 'The future-ready retail space will offer a wider experience than just physical shops but rather places in which to meet and enage wit...'
        },
    ],
    [
        {
            name: "The house of Abhinandan Lodha",
            href: '/companies/TheHouseOfAbhinandanLodha',
            src: ayodhyaAbhinandanLogo,
            about: 'The future-ready retail space will offer a wider experience than just physical shops but rather places in which to meet and enage wit...'
        },
        {
            name: "Bhutani Infra",
            href: '/companies/BhutaniInfraNoida',
            src: bhutaniInfraLogo,
            about: "Standing at the cutting edge of technology, Cyberthum is a 26.8 acre mixed-use complex and offers commercial property in Noida..."
        },
        {
            name: "Galaxy",
            href: '/companies/Galaxy',
            src: galaxyLogo,
            about: 'The future-ready retail space will offer a wider experience than just physical shops but rather places in which to meet and enage wit...'
        }
    ]
]

export const CompaniesWtUs = () => {
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const navigate = useNavigate();

    // Function to increment the index
    const incrementIndex = () => (setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length));

    // Function to decrement the index
    const decrementIndex = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex < 1) return 0;
            else return prevIndex - 1;
        });
    };

    return (
        <div className="w-[82vw] my-[4vw] mx-[auto]">
            <h1 className="font-SF-Heading-font font-normal text-white text-4xl mb-[8vh]">COMPANIES WITH US</h1>
            <div className="flex justify-between pb-5 gap-5">
                <button className="hover:bg-white bg-white text-red-500 font-semibold py-2 px-4 border border-red-500 rounded inline-flex items-center" onClick={decrementIndex}>Prev <BiLeftArrowAlt className="text-[25px] ml-[4px]" /></button>
                <button className="hover:bg-white bg-white text-red-500 font-semibold py-2 px-4 border border-red-500 rounded inline-flex items-center" onClick={incrementIndex}><BiRightArrowAlt className="text-[25px] mr-[4px]" /> Next</button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-7 gap-y-20 md:p-6]">
                {data[currentIndex]?.map((el, index) => (
                    <div key={index} className="w-full hover:w-[101%] rounded-lg shadow shadow-[0_10px_20px_grey] hover:shadow-[0_10px_20px_white] transition-transform duration-500 ease-in-out transform hover:skew-y-[0%]">
                        <div style={{ width: '100%', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>
                            <img src={el.src} alt={el.name} className="bg-[#e5dada] md:h-[40vh] sm:h-[30vh] min-h-[20vh] w-full rounded-t-lg transition-transform duration-500 ease-in-out transform hover:xl:skew-y-[20deg]" />
                        </div>
                        <div className="flex justify-between p-2 md:p-4 items-center">
                            <h2 className="text-left text-white text-base md:text-lg font-SF-Heading-font font-bold">{el.name}</h2>
                            <button className="hover:bg-white text-red-500 hover:text-red-500 font-semibold py-2 px-4 border border-red-500 rounded inline-flex items-center" onClick={() => navigate(el.href)}><BiRightArrowAlt className="mr-2" /></button>
                        </div>
                        <p className="text-white p-2 md:p-4">{el.about.substring(0, 100)}{el.about.length > 100 && <span className="hidden sm:inline">...</span>}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};