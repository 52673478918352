import React from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import MaleUser from '../../assets/team/MaleUser.png';
import FemaleUser from '../../assets/team/FemaleUser.png';

const teamMembers = [
    { id: 1, name: 'Kashak', role: 'H.R, Marketing', imageUrl: FemaleUser },
    { id: 2, name: 'Harpreet Singh', role: 'Sales Manager', imageUrl: FemaleUser },
    { id: 3, name: 'Mohit', role: 'Team Lead', imageUrl: MaleUser },
    { id: 4, name: 'Rohit', role: 'Team Lead', imageUrl: MaleUser },
    { id: 5, name: 'Abhishek', role: 'Team Lead', imageUrl: MaleUser },
    { id: 6, name: 'Komal', role: 'Sales', imageUrl: FemaleUser },
    { id: 7, name: 'Prashant', role: 'Sales', imageUrl: MaleUser },
    { id: 8, name: 'Rohit Kumar', role: 'Sales', imageUrl: MaleUser },
    { id: 9, name: 'Anshu', role: 'Sales', imageUrl: MaleUser },
    { id: 10, name: 'Ayush', role: 'Sales', imageUrl: MaleUser },
    { id: 11, name: 'Bhawna', role: 'Sales', imageUrl: FemaleUser },
    { id: 12, name: 'Mehak', role: 'Sales', imageUrl: FemaleUser }
];

export const Teams = () => {
    const targetRef = React.useRef(null);
    const { scrollYProgress } = useScroll({ target: targetRef });
    const x = useTransform(scrollYProgress, [0, 1], ["1%", "-95%"]);

    return (
        <div className="bg-neutral-800">
            <div className="flex h-48 items-center justify-center font-semibold uppercase text-white">Scroll down 👇</div>
            <section ref={targetRef} className="relative h-[300vh] bg-neutral-900">
                <div className="sticky top-0 flex h-screen items-center overflow-hidden">
                    <motion.div style={{ x }} className="flex items-center gap-4">
                        <h2 className="font-semibold text-2xl text-center lg:text-3xl text-white">Meet Our Team</h2>
                        {teamMembers.map(member => <TeamMember key={member.id} {...member} />)}
                        <p className="w-60 h-60 flex items-center justify-center font-bold text-lg md:text-xl bg-pink-800 text-white text-center rounded-full relative">
                            +49 Sales Executives
                        </p>
                    </motion.div>
                </div>
            </section>
            <div className="flex items-center justify-center font-semibold uppercase text-white">Scroll up 👆</div>
        </div>
    );
};

const TeamMember = ({ name, role, imageUrl }) => {
    return (
        <div className="flex flex-col items-center justify-center gap-4 rounded-md group relative h-[300px] w-[270px] overflow-hidden bg-white">
            <img src={imageUrl} alt="Person" className="w-48 h-48 rounded-full shadow-md object-cover" />
            <div className="text-center text-white">
                <p className="font-semibold text-lg bg-white text-black">{name}</p>
                <p className="text-[13px]">{role}</p>
            </div>
        </div>
    );
};