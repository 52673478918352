import React from 'react';
// import { useBreakpointValue, Icon } from '@chakra-ui/react'

const avatars = [
    { name: 'Ryan Florence', url: 'https://bit.ly/ryan-florence' },
    { name: 'Segun Adebayo', url: 'https://bit.ly/sage-adebayo' },
    { name: 'Kent Dodds', url: 'https://bit.ly/kent-c-dodds' },
    { name: 'Prosper Otemuyiwa', url: 'https://bit.ly/prosper-baba' },
    { name: 'Christian Nwamba', url: 'https://bit.ly/code-beast' },
]

// This blur effect is postponed to next update
// const Blur = (props) => {
//     return (
//         <Icon
//             width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
//             zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
//             height="560px"
//             viewBox="0 0 528 560"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//             {...props}>
//             <circle cx="71" cy="61" r="111" fill="#F56565" />
//             <circle cx="244" cy="106" r="139" fill="#ED64A6" />
//             <circle cy="291" r="139" fill="#ED64A6" />
//             <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
//             <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
//             <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
//             <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
//         </Icon>
//         // <svg
//         //     className="w-full md:w-40vw lg:w-30vw h-560px"
//         //     viewBox="0 0 528 560"
//         //     fill="none"
//         //     xmlns="http://www.w3.org/2000/svg"
//         //     {...props}
//         // >
//         //     <circle cx="71" cy="61" r="111" fill="#F56565" />
//         //     <circle cx="244" cy="106" r="139" fill="#ED64A6" />
//         //     <circle cy="291" r="139" fill="#ED64A6" />
//         //     <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
//         //     <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
//         //     <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
//         //     <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
//         // </svg>
//     )
// }

export const Carrer = () => {
    const [isCvUploaded, setIsCvUploaded] = React.useState(false);
    const fileInputRef = React.useRef(null);
    window.scrollTo(0, 0);
    console.log('fileInputRef',fileInputRef);

    const handleUploadClick = () => {
        fileInputRef.current.click();
        setIsCvUploaded(true);
    };

    return (
        <div className='w-[82vw] mx-auto'>
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-10 py-10 md:py-20 lg:py-32">
                <div className="space-y-10 md:space-y-20">
                    <h2 className="text-3xl sm:text-4xl lg:text-6xl font-semibold text-white">
                        Senior sale marketer{' '}<span className="text-pink-400 bg-transparent">&</span>
                        Executive/Senior Executive - Front Office
                    </h2>
                    <div className="flex items-center space-x-4 md:space-x-8">
                        <div className="flex items-center space-y-2">
                            <div className="grid grid-cols-5 sm:grid-cols-7">
                                {avatars.map((avatar) => (<img key={avatar.name} src={avatar.url} alt={avatar.name} style={{ border: '5px solid grey' }} className="w-12 h-12 md:w-16 md:h-16 rounded-full relative z-10" />))}
                                <h3 className="text-4xl md:text-6xl font-semibold text-white">+</h3>
                                <div className="w-12 h-12 md:w-16 md:h-16 flex items-center justify-center font-heading text-lg md:text-xl bg-pink-800 text-white rounded-full relative">
                                    YOU
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-4 border-black rounded-xl p-4 md:p-6 lg:p-8 max-w-lg">
                    <div className="space-y-4">
                        <h3 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-white">
                            Join our team
                            <span className="text-pink-400">!</span>
                        </h3>
                        <p className="text-white lg:text-lg md:text-base sm:text-base xs:text-base">
                            We’re looking for amazing engineers just like you! Become a part of our rockstar engineering team and skyrocket your career!
                        </p>
                    </div>
                    <form className='mt-20' action="https://formsubmit.co/aanyahomes@gmail.com" method="POST" encType="multipart/form-data">
                        <div className="space-y-4 text-white">
                            <input type="text" name="name" required placeholder="Name" className="block w-full border border-white p-2 rounded-md focus:outline-none focus:border-white" />
                            <input type="email" name="email" required placeholder="Email" className="block w-full border border-white p-2 rounded-md focus:outline-none focus:border-white" />
                            <input type="tel" name="mobile" required placeholder="Mobile No." className="block w-full border border-white p-2 rounded-md focus:outline-none focus:border-white" />
                            <button type="button" onClick={handleUploadClick} className="block w-full bg-white hover:bg-white text-black font-bold py-2 px-4 rounded-md focus:outline-none focus:bg-white">Upload CV</button>
                            <input type="file" name="cv" accept=".pdf, .docx, application/msword" hidden={!isCvUploaded} ref={fileInputRef} />
                        </div>
                        <button type="submit" className="block w-full bg-gradient-to-r from-red-400 to-pink-400 hover:bg-gradient-to-r hover:from-red-500 hover:to-pink-500 text-white font-bold py-3 px-4 rounded-md mt-8 hover:shadow-xl">Submit</button>
                    </form>
                </div>
            </div>
            {/* <Blur position={'absolute'} top={-10} left={-10} style={{ filter: 'blur(180px)' }} /> */}
        </div>
    )
}

