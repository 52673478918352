import React from "react";

export const PrivacyNotice = () => {
    window.scrollTo(0, 0);
    return (
        <div className="w-[82vw] my-[4vw] mx-[auto]">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-4xl font-bold text-white mb-4">Privacy Notice</h1>
            <div className="mb-8">
                <h2 className="text-xl sm:text-2xl font-semibold text-white mb-2">DISCLAIMER</h2>
                <p className="text-base sm:text-lg text-white">
                    This is to inform user/customer(s) that <a href="www.aanyahomes.com" className="text-white" target="_blank">www.aanyahomes.com</a> is the
                    only official website of G.A.H Aanya Homes Pvt. Ltd User/Customers)
                    are cautioned and advised not to rely upon any information stated on
                    any other websites that may appear to be similar to the Company's
                    official website. This website is for guidance only. It does not
                    constitute part of an offer or contract. Design & specifications are
                    subject to change without prior notice. Computer-generated images are
                    the artist's impression and are indicative of the actual designs. The
                    particulars contain the details of the Projects/developments
                    undertaken by the Company including the depiction of banners/posters
                    of the Project. The contents are subject to change in terms of the
                    stipulations/recommendations provided from time to time under the Real
                    Estate (Regulation and Development) Act, 2016 and Rules made thereunder
                    ("RERA") and accordingly, may not be fully in line thereof as of date.
                    You are therefore required to verify all the details, including area,
                    amenities, services, terms of sales and payment, and other relevant
                    terms independently with the sales team/company before concluding any
                    decision for buying any units) in any of the said projects. The Company
                    will not in any circumstances be liable for any expenses, losses, or
                    damages including, without limitation, indirect or consequential losses
                    or damages or any expenses whatsoever arising out of the use of this
                    website. Your use of the website and your reliance on any information
                    on the website is solely at your own risk.
                </p>
            </div>
            <div>
                <h2 className="text-xl sm:text-2xl font-semibold text-white mb-2">PUBLIC NOTICE</h2>
                <p className="text-base sm:text-lg text-white">
                    TO WHOMSOEVER IT MAY CONCERN This is to bring to your kind notice
                    that in compliance of the Real Estate (Regulation and Development)
                    Act, 2016 ("RERA") G.A.H Aanya Homes Pvt Ltd is strictly against
                    promotion, marketing and sale of projects without obtaining RERA
                    registration of its projects. Therefore, in the event any broker/
                    channel partner engages in any such activity of promotion, marketing,
                    and sale of the unregistered projects of the Company, the Company
                    completely disclaims any liability in this regard. In no event will
                    the Company be liable for any claim made by anyone including seeking
                    any cancellation for any of the inaccuracies in the information
                    provided by such brokers/channel partners, though all efforts have
                    to be made to ensure accuracy. The Company under no circumstance will
                    be liable for any expense, loss, or damage including, without
                    limitation, indirect or consequential loss or damage, or any expense,
                    loss, or damage whatsoever arising from such promotion, marketing,
                    and sale of unregistered projects.
                </p>
            </div>
        </div>
    );
};
