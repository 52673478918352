import React from 'react';
import { BiRightArrowAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import cyberthum from '../assets/projects/carausals/cyberthum.png'
import avenue62 from '../assets/projects/carausals/avenue62.png'
import cityCenter150 from '../assets/projects/carausals/citycenter150.png'
import sawasdeeHeights from '../assets/projects/carausals/sawasdee-heights.png'
import sawasdeeNsp from '../assets/projects/carausals/sawasdee-nsp.png'
import sawasdeeGems from '../assets/projects/carausals/sawasdee-gems.png'
import fairfox from '../assets/projects/carausals/fairfox.png'
import yugen from '../assets/projects/carausals/yugen.png'
import signatureGlobalCity79B from '../assets/projects/carausals/signatureGlobalCity79B.png'
import galleria99 from '../assets/projects/carausals/galleria99.png'
import theCullinan from '../assets/projects/carausals/the-cullinan.png'
import theLine from '../assets/projects/carausals/the-line.png'
import gulfOfGoa from '../assets/projects/carausals/gulf-of-goa.png'
import kailasa from '../assets/projects/carausals/kailasa.png'
import migsunRohiniCentral from '../assets/projects/carausals/migsunRohiniCentral.png'

const data = [
  {
    id: 1,
    name: "Cyberthum",
    href: '/our-projects/cyberthum',
    src: cyberthum,
    about: "Standing at the cutting edge of technology, Cyberthum is a 26.8 acre mixed-use complex and offers commercial property in Noida..."
  },
  {
    id: 2,
    name: "Avenue 62",
    href: '/our-projects/avenue62',
    src: avenue62,
    about: 'The future-ready retail space will offer a wider experience than just physical shops but rather places in which to meet and enage wit...'
  },
  {
    id: 3,
    name: "City Center 150",
    href: "/our-projects/citycenter150",
    src: cityCenter150,
    about: "City Center 150 is the new address for experimential retail. It offers a glimpse into the future of listyle and retail areas..."
  },
  {
    id: 4,
    name: "Sawasdee Heights",
    href: "/our-projects/sawasdeeHeights",
    src: sawasdeeHeights,
    about: "Sawasdee Heights is a residential project strategically located in the adjoining prestigious areas of Punjabi Bagh and..."
  },
  {
    id: 5,
    name: "Sawasdee NSP",
    href: "/our-projects/sawasdeeNsp",
    src: sawasdeeNsp,
    about: "Netaji Subash Palace (NSP), has overtime become the hub for commercial and office spaces in Delhi..."
  },
  {
    id: 6,
    name: "Fair Fox",
    href: "/our-projects/fairfox",
    src: fairfox,
    about: "Fairfox IT infra, Simple philosophy is based on assuring Quality Construction, Timely Delivery, Corporates..."
  },
  {
    id: 7,
    name: "Sawasdee Gems",
    href: "/our-projects/sawasdeeGems",
    src: sawasdeeGems,
    about: "Sawasdee Gems is a retail & office development that will consist of the best of facilities. Sawasdee Gems Delhi is..."
},
  {
    id: 8,
    name: "Yugen",
    href: "/our-projects/yugen",
    src: yugen,
    about: "We at Yugen Infra plan to delight Indians with luxurious yet affordable properties..."
  },
  {
    id: 9,
    name: "Signature Global City 79B",
    href: "/our-projects/signature-global-city-79B",
    src: signatureGlobalCity79B,
    about: "Signature Global City 79B creates an exclusive ambience that echoes with ageless folklore of the Aravallis,..."
  },
  {
    id: 10,
    name: "Galleria 99",
    href: "/our-projects/galleria99",
    src: galleria99,
    about: "Part of a mega high-street improvement bang on Dwarka Expressway – Gurugram. The Retail Shops in Ocus Medley 99..."
  },
  {
    id: 11,
    name: "Kailasa",
    href: "/our-projects/kailasa",
    src: kailasa,
    about: "TARC Kailāsa is located iconically on Patel Road. Patel Road serves as the main artery to Central Delhi,..."
  },
  {
    id: 12,
    name: "Migsun Rohini Central",
    href: "/our-projects/migsunRohiniCentral",
    src: migsunRohiniCentral,
    about: "Nestled in the vibrant city of Delhi, Migsun Rohini Central stands as a prominent high-street commercial project developed by the prestigious Migsun Group..."
  },
  {
    id: 13,
    name: "The Cullinan",
    href: "/our-projects/theCullinan",
    src: theCullinan,
    about: "Legends live forever, so will the Cullinan remain a legend for eternity and just as the uncut diamond was later cut and polished into 9 different gems..."
  },
  {
    id: 14,
    name: "The Line",
    href: "/our-projects/theLine",
    src: theLine,
    about: "M3M The LINE stands tall as a testament to opulence, opportunity and growth. This premium commercial real estate project in Noida Sector 72..."
  },
  {
    id: 15,
    name: "Gulf of Goa",
    href: "/our-projects/gulfOfGoa",
    src: gulfOfGoa,
    about: "The prestigious shores have hosted a symphony of blockbusters, with the likes of Goa Marriot Hotel setting the stage for countless silver screen memories...."
  }
]

export const OurProjects = () => {
  const navigate = useNavigate();
  window.scrollTo(0, 0);

  return (
    <div className="w-[82vw] my-[2vw] mx-[auto]">
      <div>
        <h1 className="font-SF-Heading-font font-normal text-white text-4xl text-center mb-[8vh]">OUR PROJECTS</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-7 gap-y-20 md:p-6]">
          {data?.map((el, index) => (
            <div key={index} className="w-full hover:w-[101%] rounded-lg shadow shadow-[0_10px_20px_grey] hover:shadow-[0_10px_20px_white] transition-transform duration-500 ease-in-out transform hover:skew-y-[0%]">
              <div style={{ width: '100%', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>
                <img src={el.src} alt={el.name} className="md:h-[40vh] sm:h-[30vh] min-h-[20vh] w-full object-cover rounded-t-lg transition-transform duration-500 ease-in-out transform hover:xl:skew-y-[20deg]" />
              </div>
              <div className="flex justify-between p-2 md:p-4 items-center">
                <h2 className="text-left text-white text-base md:text-lg font-SF-Heading-font font-bold">{el.name}</h2>
                <button className="hover:bg-white text-red-500 hover:text-red-500 font-semibold py-2 px-4 border border-red-500 rounded inline-flex items-center" onClick={() => navigate(el.href)}><BiRightArrowAlt className="mr-2" /></button>
              </div>
              <p className="text-white font-semibold p-2 md:p-4">{el.about.substring(0, 100)}{el.about.length > 100 && <span className="hidden sm:inline">...</span>}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

