import React from 'react';
import Navbar from '../Components/Navbar';
import { Homepage } from '../Pages/Homepage';
import { NotFound } from '../Pages/NotFound';
import Footer from '../Components/Footer';
import { About } from '../Pages/About';
import { Project } from '../Components/Project';
import { SocialHandles } from '../Components/SocialHandles';
import { Carrer } from '../Pages/Carrer';
import { Contact } from '../Pages/Contact';
import { Blogs } from '../Pages/Blogs';
import { BlogDetail } from '../Components/Blogs/BlogDetail';
import { OurProjects } from '../Pages/OurProjects';
import { PrivacyNotice } from '../Pages/PrivacyNotice';
import { Company } from '../Pages/Company';

const withLayout = (WrappedComponent) => {
  return () => (
    <>
      <Navbar />
      <WrappedComponent />
      <SocialHandles />
      <Footer />
    </>
  );
};

const Pages = {
  HomepageLayout: withLayout(Homepage),
  NotFoundLayout: withLayout(NotFound),
  AboutLayout: withLayout(About),
  OurProjectsLayout: withLayout(OurProjects),
  ProjectLayout: withLayout(Project),
  CompanyLayout: withLayout(Company),
  BlogsLayout: withLayout(Blogs),
  BlogDetailLayout: withLayout(BlogDetail),
  CarrerLayout: withLayout(Carrer),
  ContactLayout: withLayout(Contact),
  PrivacyNoticeLayout: withLayout(PrivacyNotice),
};

export default Pages;